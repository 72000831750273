<template>
	<w-section :title="$t('document-edition.preferences.title')">
		<w-text-info class="mb-2" :text="$t('document-edition.preferences.presentation_text')" />
		<w-checkbox
			class="my-1"
			false-value="none"
			:indeterminate="globalPreferencesStatus === 'some'"
			label="Microsoft Office"
			true-value="all"
			:value="globalPreferencesStatus"
			@input="updateEditionPreferences($event)"
		/>
		<w-checkbox class="ml-3 my-1" :value="preferences.msexcel" @input="updateEditionPreference('msexcel', $event)">
			<template v-slot:label>
				<w-layout align-center>
					<v-icon class="mr-2" color="#207247"> fas fa-file-excel </v-icon>
					Microsoft Excel
				</w-layout>
			</template>
		</w-checkbox>
		<w-checkbox class="ml-3 my-1" :value="preferences.mspowerpoint" @input="updateEditionPreference('mspowerpoint', $event)">
			<template v-slot:label>
				<w-layout align-center>
					<v-icon class="mr-2" color="#D24726"> fas fa-file-powerpoint </v-icon>
					Microsoft PowerPoint
				</w-layout>
			</template>
		</w-checkbox>
		<w-checkbox class="ml-3 my-1" :value="preferences.msword" @input="updateEditionPreference('msword', $event)">
			<template v-slot:label>
				<w-layout align-center>
					<v-icon class="mr-2" color="#2A5699"> fas fa-file-word </v-icon>
					Microsoft Word
				</w-layout>
			</template>
		</w-checkbox>

		<w-text-alert class="mt-3" :text="$t('document-edition.preferences.warn_text')" />
	</w-section>
</template>

<script>
import DocumentEditionModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentEditionModuleGuard'

export default {
	name: 'DocumentEditionPreferences',
	mixins: [DocumentEditionModuleGuard],
	data: function () {
		return {
			preferences: {
				msexcel: false,
				mspowerpoint: false,
				msword: false
			}
		}
	},
	computed: {
		globalPreferencesStatus: function () {
			const values = Object.values(this.preferences)
			if (values.every(val => val)) {
				return 'all'
			} else if (values.some(val => val)) {
				return 'some'
			}
			return 'none'
		}
	},
	mounted: function () {
		this.loadPreferences()
	},
	methods: {
		loadPreferences: function () {
			this.service.loadPreferences().then(preferences => {
				Object.assign(this.preferences, preferences)
			})
		},
		updateEditionPreference: function (fieldName, fieldValue) {
			this.service.updatePreferences({ [fieldName]: fieldValue }).then(() => {
				this.preferences[fieldName] = fieldValue
			})
		},
		updateEditionPreferences: function (value) {
			let updatedPreferences = {}
			if (value === 'all') {
				value = true
			} else {
				value = false
			}
			Object.entries(this.preferences).forEach(([fieldName, fieldValue]) => {
				if (fieldValue !== value) {
					updatedPreferences[fieldName] = value
				}
			})
			this.service.updatePreferences(updatedPreferences).then(preferences => {
				Object.assign(this.preferences, preferences)
			})
		}
	}
}
</script>
