import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const getWebDavURL = (vendorId, documentId) => {
	const url = `vendors/${vendorId}/documents/${documentId}/webdav_url`
	return axios.get(url)
}

class Api {
	constructor (routesList, config = {}) {
		this.axios = Configurator.defaultConfiguration(config.baseUrl)
		routesList.forEach(route => {
			this.createApiPoint(route)
		})
	}
	createApiPoint (route) {
		const routeName = route.name
		delete route.name
		this[routeName] = function (data, cancelToken = null) {
			if (cancelToken) {
				cancelToken.cancel('Request cancelled')
			}
			const newCancelToken = Configurator.CancelToken.source()
			let promise = null
			const body = this.getBody(route.body, data)
			const url = route.url
			switch (route.method) {
				case 'GET':
					promise = this.axios.get(url, {
						...(route.config || {}),
						cancelToken: newCancelToken.token
					})
					break
				case 'PATCH':
					promise = this.axios.patch(url, body, {
						cancelToken: newCancelToken.token
					})
					break
				case 'POST':
					break
			}
			return {
				cancelToken: newCancelToken,
				promise: promise
			}
		}
	}
	getBody (bodyParams, data = {}) {
		if (!bodyParams || !Array.isArray(bodyParams)) {
			return {}
		}
		let result = {}
		bodyParams.forEach(param => {
			if (data.hasOwnProperty(param) && data[param] !== undefined) {
				result[param] = data[param]
			}
		})
		return result
	}
}

const routes = [
	{
		method: 'GET',
		name: 'getPreferences',
		url: 'users/me/document-edition-preferences'
	},
	{
		body: ['msexcel', 'mspowerpoint', 'msword'],
		method: 'PATCH',
		name: 'updatePreferences',
		url: 'users/me/document-edition-preferences'
	}
]

const DocumentEditionApi = new Api(routes, {
	baseUrl: Config.VUE_APP_ROOT_API + '/api/'
})

const libreOfficeRoutes = [
	{
		config: {
			responseType: 'document',
			forbiddenHeaders: ['OAuthProvider']
		},
		method: 'GET',
		name: 'listSettings',
		url: '/hosting/discovery'
	}
]

DocumentEditionApi.getWebDavURL = getWebDavURL

DocumentEditionApi.libreOffice = new Api(libreOfficeRoutes, {
	baseUrl: Config.VUE_APP_WOPI_BASE_URL
})

export default DocumentEditionApi
